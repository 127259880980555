import { connect } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import {
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
} from "@ionic/react";

import {
  IconMessageCircle,
  IconOutbound,
  IconSettings,
  IconLayoutDashboard,
  IconBrain,
} from "@tabler/icons-react";

import { FormattedMessage } from "react-intl";

import Chats from "./mobile/Chats";
import Chat from "./mobile/Chat";
import MobileSettings from "./mobile/Settings";
import ContactDetail from "./mobile/ContactDetail";

import AiSettings from "./mobile/AiSettings";
import Language from "./mobile/Language";
import Home from "../../components/home";
import Integrations from "../../components/integrations";
import Payment from "./mobile/Payment";
import OutboundLogs from "../../components/outbound/logs/mobile";
import UpdateAppVersion from "./mobile/UpdateAppVersion";
import AiSettingsInstructions from "./mobile/AiSettingsInstructions";
import Outbound from "../../components/outbound";
import NavBanner from "../../components/NavBanner";
import MobilePlayGround from "../../components/ai/mobile";
import AIHome from "../../components/ai/mobile-home";
import AccountNewInvitation from "../../components/account/accept-invitation";

import { User, Event, Settings } from "../../interfaces";
import AiEmbeddings from "./mobile/AiEmbeddings";

const MobileHome: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const {
    logOut,
    user,
    settings,
    onRequestSettings,
    refreshUser,
    switchCompany,
  } = props;
  const location = useLocation();
  const { pathname } = location;
  const tabs = [
    "/home",
    "/chats",
    "/outbound",
    "/settings",
    "/language",
    "/ai",
    "/account/accept-invitation",
  ];

  const hide = !tabs.includes(pathname);
  const tabBarStyle = hide ? { display: "none" } : undefined;

  return (
    <>
      <NavBanner settings={settings} mode={"mobile"} hide={hide} />
      <IonTabs>
        <IonRouterOutlet>
          <Route
            exact
            path="/home"
            render={() => <Home user={user} settings={settings} />}
          />
          <Route exact path="/chats" render={() => <Chats />} />
          <Route
            exact
            path="/outbound"
            render={() => <Outbound settings={settings} />}
          />
          <Route path="/outbound/:id" component={OutboundLogs} />

          <Route
            exact
            path="/settings"
            render={() => (
              <MobileSettings
                logOut={logOut}
                user={user}
                settings={settings}
                switchCompany={switchCompany}
              />
            )}
          />

          <Route path="/chat/:id/contact" component={ContactDetail} />
          <Route
            exact
            path="/chat/:id"
            render={(routeProps) => (
              <Chat {...routeProps} settings={settings} />
            )}
          />

          <Route path="/integrations" component={Integrations} />

          <Route exact path="/ai-settings" component={AiSettings} />
          <Route path="/ai-settings/:type" component={AiSettingsInstructions} />
          <Route exact path="/ai-embeddings" component={AiEmbeddings} />

          <Route exact path="/ai" component={AIHome} />
          <Route exact path="/ai-playground" component={MobilePlayGround} />

          <Route path="/download-new-version" component={UpdateAppVersion} />

          <Route exact path="/integrations" component={Integrations} />
          <Route
            exact
            path="/settings/language"
            render={() => <Language user={user} />}
          />

          <Route
            exact
            path="/settings/payment"
            render={() => (
              <Payment
                user={user}
                settings={settings}
                onRequestSettings={onRequestSettings}
              />
            )}
          />

          <Route
            exact
            path="/account/accept-invitation"
            render={(routeProps) => (
              <AccountNewInvitation
                {...routeProps}
                refreshUser={refreshUser}
                user={user}
                settings={settings}
              />
            )}
          />

          <Route exact path="/" render={() => <Redirect to="/chats" />} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom" style={tabBarStyle}>
          <IonTabButton className="visito-tabs" tab="home" href="/home">
            <IconLayoutDashboard size={26} />
            <IonLabel>
              {" "}
              <FormattedMessage id="nav.home" />
            </IonLabel>
          </IonTabButton>
          <IonTabButton className="visito-tabs" tab="chats" href="/chats">
            <IconMessageCircle size={26} />
            <IonLabel>
              {" "}
              <FormattedMessage id="nav.inbox" />
            </IonLabel>
          </IonTabButton>
          <IonTabButton className="visito-tabs" tab="ai" href="/ai">
            <IconBrain size={26} />
            <IonLabel>
              <FormattedMessage id="nav.ai" />
            </IonLabel>
          </IonTabButton>
          {settings.events ? (
            <IonTabButton
              className="visito-tabs"
              tab="outbound"
              href="/outbound"
            >
              <IconOutbound size={26} />
              <IonLabel>
                {" "}
                <FormattedMessage id="nav.outbound" />
              </IonLabel>
            </IonTabButton>
          ) : null}

          <IonTabButton
            className="visito-tabs"
            tab="home/settings"
            href="/settings"
          >
            <IconSettings size={26} />
            <IonLabel>
              <FormattedMessage id="nav.settings" />
            </IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};

interface ILayoutProps {
  logOut: Function;
  user: User;
  event: Event;
  settings: Settings;
  onRequestSettings: Function;
  refreshUser: Function;
  switchCompany: Function;
}

export default connect((props: any) => ({
  settings: props.app.settings,
}))(MobileHome);
